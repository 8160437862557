export const photoList: MediaImageMap[] = [
    {
        alt: 'Amsterdam Sunset',
        googleId: '1n--VZ4bcA8bijjhW7BYGINJsybldVG0c',
        caption: 'A sunset in Amsterdam'
    },
    {
        alt: 'Amsterdam Pond',
        googleId: '1mh4eP675fmSpDC_H4MmD3SUaruh1_Kce',
        caption: 'A random pond in Amsterdam'
    },
    {
        alt: 'Madeiran Mountains',
        googleId: '1lGMxg4D_fqetfew-_acXXL34wgX2PWuU',
        caption: 'Mountains in Madeira'
    },
    {
        alt: 'Japanese Tea House',
        googleId: '1O61Q0FWUKg5qxFaBdFpx5sE9QrSDKWLg',
        caption: 'A Quaint Japanese Tea House'
    },
    {
        alt: 'Boats in Amsterdam',
        googleId: '118_kf-klrpoNjSI9AXFJRQyZnwsQEy1f',
        caption: 'Random Amsterdam Boat'
    },
    {
        alt: 'China from China',
        googleId: '17VgaWDddnzl6BKSZ3YEWj7m0jpKSHBik',
        caption: 'China from China'
    },
    {
        alt: 'Summer vibes in Amsterdam',
        googleId: '1-23m3HG4gJ6z_R6QgvJhk9l7czrtqRus',
        caption: 'Summer vibes in Amsterdam'
    },
    {
        alt: 'Alone in Porto',
        googleId: '1x5RGI0PPbJ2AfA8LNnJV1CCYjUpJ2jn6',
        caption: 'Alone in Porto'
    },
    {
        alt: 'Focus',
        googleId: '1jzNv9oQ1kW5E9_HZlrwolkchxlo5weGb',
        caption: 'Focus'
    },
    {
        alt: 'Reflections of Porto',
        googleId: '178netzR3wuZhUX3devqWvbVE3LZgnGpw',
        caption: 'Reflections of Porto'
    },
    {
        alt: 'Oxford at Christmas',
        googleId: '1XNxUuZCovxqNT47b3fQtdMRbT7B_cglB',
        caption: 'Oxford at Christmas'
    },
    {
        alt: 'Lanterns',
        googleId: '18JCmkOXgARXNXqIHxKVRrBCEGkYvfEFr',
        caption: 'Chinese Lanterns'
    }
]

export interface MediaImageMap {
    alt: string,
    googleId: string,
    width?: number,
    caption: string
}

export const videoList: MediaImageMap[] = [
    {
        alt: 'Stairway to Heaven solo',
        googleId: '1hdf4wEeKMvUrsOzUYS9dZqMYs7aEJ1aG',
        caption: 'The solo from Stairway To Heaven - Led Zeppelin'
    },
    {
        alt: 'Live Forever solo',
        googleId: '1gdUyL4AeeyUvd36wt6tld5WPaRn4OwZ2',
        caption: 'The solo from Live Forever - Oasis'
    },
    {
        alt: 'Smoke on the Water solo',
        googleId: '12Rg8U5SVo_CZFPIgItKSQwjoa_ruaNSK',
        caption: 'The solo from Smoke On The Water - Deep Purple'
    },
    {
        alt: 'Layla (Unplugged) solo',
        googleId: '1TX_xCSQw3ltD866XZdRNwm47kpkFjXSI',
        caption: 'The solo from Layla (Unplugged) - Eric Clapton'
    }
]